<template>
  <div class="">
    <trac-loading v-if="isLoading" />
  <main v-else class="mr-4 md:mr-0">
      <trac-back-button>Users &amp; Roles</trac-back-button>
    <div class="flex items-center justify-between my-6 px-2">
      <div class="mt-4">
        <h2 class="text-xs">Settings</h2>
        <h1 class="font-medium mt-2">Existing Roles</h1>
      </div>
      <div>
        <trac-button @button-clicked="$router.push({ name: 'ManageRolesPermissions' })">
          <div class="px-4">Create Role</div>
        </trac-button>
      </div>
    </div>
    <!-- <div class="bg-white mt-12 rounded-lg overflow-hidden big-shadow">
      <div class="mt-8 mb-5 mx-8 justify-end flex flex-row">
        <div class="flex items-center cursor-pointer">
          <span class="text-xs font-semibold py-2 uppercase text-primaryBlue"
            >Manage (2) Selected roles</span
          >
          <div>
            <svg
              class="w-3 h-4 ml-1"
              viewBox="0 0 7 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 11.7998L6.4 6.39981L1 0.999805"
                stroke="#003283"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="mx-8 mb-8 border rounded-lg overflow-auto">
        <table class="w-full table-auto overflow-auto">
          <thead class="bg-blue-100">
            <tr class="h-12">
              <th class="text-left pl-8 text-xs font-semibold py-2 uppercase">
                Role
              </th>
              <th class="text-left px-5 text-xs font-semibold py-2 uppercase">
                Date Created
              </th>
              <th class="text-left text-xs font-semibold py-2 uppercase">
                Locations
              </th>
              <th class="text-left text-xs font-semibold py-2 uppercase">
                Employees
              </th>
            </tr>
          </thead>
          <tbody v-if="roles.length > 0">
            <tr
              class="cursor-pointer"
              :class="index % 2 === 0 ? '' : 'bg-gray-100'"
              v-for="(role, index) in roles"
              :key="index"
              @click="
                $router.push({
                  name: 'OrderDetails',
                  params: {
                    id: role.id,
                  },
                })
              "
            >
              <td class="text-xs py-5 text-accentDark font-medium pl-8">
                <div class="flex items-center">
                  <trac-checkbox></trac-checkbox>
                  <span class="pl-4 capitalize">{{ role.role }}</span>
                </div>
              </td>
              <td class="text-xs py-5 px-5 text-accentDark font-bold">
                {{ moment().format("ll") }}
              </td>
              <td class="text-xs py-5 text-accentDark font-medium">
                -
              </td>
              <td class="text-xs py-5 text-accentDark">
                <div class="flex flex-row">-</div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="py-6 pl-5 text-xs">There is no available data</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> -->
    <div class="flex w-full justify-center items-center">
      <div class="max-w-sm mt-32 w-full">
        <trac-dropdown-exteneded :neededProperty="'role'" :shouldFilter="true" :options="roles" @optionSelected="selecteRole" selector="Select Role" />
      </div>
    </div>
  </main>
  </div>
</template>

<script>
import moment from 'moment'
import { DELETE_LOCAL_DB_DATA, SAVE_LOCAL_DB_DATA } from '../../browser-db-config/localStorage';
import { eventBus } from "./../../main";

export default {
  data() {
    return {
      moment,
      isLoading: false,
      roles: null
    }
  },
  async created() {
    DELETE_LOCAL_DB_DATA('role-details');
    this.isLoading = true;
    await this.fetchAllUsers();
    this.isLoading = false;
  },
  methods: {
    selecteRole(option) {
      SAVE_LOCAL_DB_DATA('role-details', option);
      this.$router.push({ name: 'ManageRolesPermissions' });
    },
    async fetchAllUsers() {
      await this.$store.dispatch('FETCH_ALL_ROLES');

      const res = this.$store.getters['GET_ALL_ROLES'];

      if (res.status) {
        this.roles = res.data || [];
        if (this.roles) {
          this.roles = this.roles.sort((a,b) => a.role.toLowerCase() > b.role.toLowerCase() ? 1 : -1);
        }
      } else {
        // alert(res.message);
        eventBus.$emit('trac-alert', { message: res.message });
      }
    }
  }
}
</script>

<style>
</style>